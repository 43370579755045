<template>
  <Modal v-model="showModal" :mask-closable="false" :width="item.width">
    <div slot="header">{{showLang('com.edit.lamp.info.batch')}}</div>
    <Form ref="form" :model="form" :rules="rules" :label-width="130">
      <FormItem v-if="['updateGroup'].includes(item.code)" prop="name" :label="showLang('com.light.group')" style="margin-right: 15px">
        <Select :placeholder="showLang('save.select')" v-model="form.groupId" style="width: 200px; margin: 0 10px;">
          <template v-for="(s, idx) in allGroups">
            <Option :value="s.id" :key="idx">{{s.name}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem v-if="['updateUseType'].includes(item.code)" prop="name" :label="showLang('com.lamp.type')" style="margin-right: 15px">
        <Select :placeholder="showLang('save.select')" v-model="form.useType" style="width: 200px; margin: 0 10px;">
          <template v-for="(item, idx) in args.useTypes">
            <Option :value="item.id" :key="idx">{{item.name}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem v-if="['updateLightGroup'].includes(item.code)" prop="name" :label="showLang('com.light.group')" style="margin-right: 15px">
        <Select :placeholder="showLang('save.select')" v-model="form.lightGroupId" style="width: 200px; margin: 0 10px;">
          <template v-for="(s, idx) in args.groups">
            <Option :value="s.id" :key="idx">{{s.name}}[{{s.code}}]</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem v-if="['updateConnection'].includes(item.code)" label="设备类型" style="margin-right: 15px">
        <div class="flex-row">
          <Select :placeholder="showLang('save.select')" v-model="form.deviceType" style="width: 200px; margin-right: 33px;">
            <Option v-for="(s, idx) in args.deviceTypes" :key="idx" :value="s.code">{{s.name}}</Option>
          </Select>
          <template v-if="args.gateways.length > 0">
            关联网关
            <Select :placeholder="showLang('save.select')" v-model="form.gatewayId" style="width: 150px; margin-left: 10px; margin-right: 10px;">
              <Option value="xx">{{showLang('com.state.not')}}</Option>
              <template v-for="(s, idx) in args.gateways">
                <Option :value="s.id" :key="idx">{{s.name}}</Option>
              </template>
            </Select>
            上行通道号
            <Input type="number" v-model="form.channel" placeholder="请输入通信ID" style="width: 150px; margin-left: 10px;"></Input>
          </template>
        </div>
      </FormItem>
      <FormItem v-if="['updateVoltageAlarm'].includes(item.code)" prop="env" :label="showLang('com.state.is.v.police')" style="margin-right: 15px">
        <div class="flex-row">
          <i-switch v-model="form.voltageAlarm" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input type="number" v-model="form.voltageLower" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.lower.val')}}</span><span
            slot="append">V</span></Input>
          <Input type="number" v-model="form.voltageUpper" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.upper.val')}}</span><span
            slot="append">V</span></Input>
        </div>
      </FormItem>
      <FormItem v-if="['updateTempAlarm'].includes(item.code)" prop="ent" :label="showLang('com.state.is.t.police')" style="margin-right: 15px">
        <div class="flex-row">
          <i-switch v-model="form.tempAlarm" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input type="number" v-model="form.tempLevel1" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.lighting.T.min')}}</span><span
            slot="append">℃</span></Input>
          <Input type="number" v-model="form.tempLevel2" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.lighting.T.max')}}</span><span
            slot="append">℃</span></Input>
        </div>
      </FormItem>
      <template v-for="n in 2">
        <FormItem :key="`${n}-1`" v-if="['updateChannel'].includes(item.code)" :label="`${n}路控制参数`" style="margin-right: 15px">
          <div class="flex-row">
            <i-switch v-model="form[`enable${n}`]" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
              <span slot="open">{{showLang('com.state.enable')}}</span>
              <span slot="close">{{showLang('com.state.disable')}}</span>
            </i-switch>
            <Input type="number" v-model="form[`before${n}`]" style="width: 200px; margin-right:10px">
              <span slot="prepend">{{showLang('com.import.cn.lamp.p')}}</span>
              <span slot="append">W</span>
            </Input>
            <Input type="number" v-model="form[`after${n}`]" style="width: 200px; margin-right:10px">
              <span slot="prepend">{{showLang('com.lighting.pr1')}}</span>
              <span slot="append">W</span>
            </Input>
            <Input type="number" v-model="form[`count${n}`]" style="width: 150px; margin-right:10px"><span slot="prepend">{{showLang('com.import.cn.lamp.num')}}</span></Input>
            <Input type="number" v-model="form[`adjust${n}`]" style="width: 200px; margin-right:10px"><span slot="prepend">{{showLang('com.import.cn.lamp.pc')}}</span></Input>
          </div>
        </FormItem>
        <FormItem :key="`${n}-2`" v-if="['updatePlanMode'].includes(item.code)" :label="`${n}路控制方式`" style="margin-right: 15px">
          <div class="flex-row">
            <Select :placeholder="showLang('save.select')" v-model="form[`mode${n}`]" style="width: 200px; margin-right: 10px;">
              <Option v-for="(s, idx) in args.modes" :key="idx" :value="s.id">{{s.name}}</Option>
            </Select>
            关联计时日表：
            <Select :placeholder="showLang('save.select')" v-model="form[`planId1${n}`]" style="width: 200px; margin-right: 10px;">
              <Option v-for="(s, idx) in args.plan1s" :key="idx" :value="s.id">{{s.name}}</Option>
            </Select>
            关联准时日表：
            <Select :placeholder="showLang('save.select')" v-model="form[`planId2${n}`]" style="width: 200px; margin-right: 10px;">
              <Option v-for="(s, idx) in args.plan2s" :key="idx" :value="s.id">{{s.name}}</Option>
            </Select>
          </div>
        </FormItem>
        <FormItem :key="`${n}-3`" v-if="['updateCurrentAlarm'].includes(item.code)" :label="`${n}路电流报警`" style="margin-right: 15px">
          <div class="flex-row">
            <i-switch v-model="form[`currentAlarm${n}`]" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
              <span slot="open">{{showLang('com.state.enable')}}</span>
              <span slot="close">{{showLang('com.state.disable')}}</span>
            </i-switch>
            <Input type="number" v-model="form[`currentLower${n}`]" style="width: 250px; margin: 0 5px;">
              <span slot="prepend">{{showLang('com.data.lower.val')}}</span>
              <span slot="append">A</span>
            </Input>
            <Input type="number" v-model="form[`currentUpper${n}`]" style="width: 250px; margin: 0 5px;">
              <span slot="prepend">{{showLang('com.data.upper.val')}}</span>
              <span slot="append">A</span>
            </Input>
          </div>
        </FormItem>
        <FormItem :key="`${n}-4`" v-if="['updateLeakageAlarm'].includes(item.code)" :label="`${n}路漏电报警`" style="margin-right: 15px">
          <div class="flex-row">
            漏电电流报警
            <i-switch v-model="form[`leakageCurrentAlarm${n}`]" size="large" true-color="green" false-color="red" style="margin:10px;width:56px">
              <span slot="open">{{showLang('com.state.enable')}}</span>
              <span slot="close">{{showLang('com.state.disable')}}</span>
            </i-switch>
            <Input type="number" v-model="form[`leakageCurrentUpper${n}`]" style="width: 250px; margin: 0 10px;">
              <span slot="prepend">漏电电流阈值</span>
              <span slot="append">mA</span>
            </Input>
            漏电电压报警
            <i-switch v-model="form[`leakageVoltageAlarm${n}`]" size="large" true-color="green" false-color="red" style="margin:10px;width:56px">
              <span slot="open">{{showLang('com.state.enable')}}</span>
              <span slot="close">{{showLang('com.state.disable')}}</span>
            </i-switch>
            <Input type="number" v-model="form[`leakageVoltageUpper${n}`]" style="width: 250px; margin: 0 10px;">
              <span slot="prepend">漏电电压阈值</span>
              <span slot="append">V</span>
            </Input>
          </div>
        </FormItem>
      </template>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalEditArgs',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
    args: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: this.value,
      // showSelectModal: false,
      loading: false,
      isAdd: false,
      form: {
        id: 0,
        lightGroupId: 0, //所属灯控分组ID
        gatewayId: '', //所在网关ID
        groupId: 0, //所在分区ID
        useType: 101, //应用类型
        deviceType: 0, //设备类型
        code: '',
        name: '',
        channel: 6, //有网关的前提下，与网关的通信信道号
        voltageAlarm: false, //是否电压报警
        voltageLower: 80, //电压报警下限
        voltageUpper: 280, //电压报警上限
        tempAlarm: false, //是否温度报警
        tempLevel1: 50, //温度一级报警阈值
        tempLevel2: 80, //温度二级报警阈值
        gyroAlarm: false, //是否倾斜报警
        buildDate: "2024-04-01",
        enable1: true, //通道是否启用
        before1: 250, //通道改造前功率
        after1: 100, //通道当前功率
        count1: 1, //通道光源数量
        adjust1: 1, //通道测量系数
        planId11: 0, //通道关联计时日表ID，
        planId21: 0, //通道关联准时日表ID，
        mode1: 0, //通道控制方式
        currentAlarm1: false, //通道是否电流报警
        currentLower1: 0.3, //通道电流报警下限
        currentUpper1: 10, //通道电流报警上限
        leakageCurrentAlarm1: false, //通道是否漏电电流报警
        leakageCurrentUpper1: 500, //漏电电流报警阈值
        leakageVoltageAlarm1: false, //通道是否漏电电压报警
        leakageVoltageUpper1: 100, //漏电电压报警阈值
        enable2: true, //通道是否启用
        before2: 250, //通道改造前功率
        after2: 100, //通道当前功率
        count2: 1, //通道光源数量
        adjust2: 1, //通道测量系数
        planId12: 0, //通道关联计时日表ID，
        planId22: 0, //通道关联准时日表ID，
        mode2: 0, //通道控制方式
        currentAlarm2: false, //通道是否电流报警
        currentLower2: 0.3, //通道电流报警下限
        currentUpper2: 10, //通道电流报警上限
        leakageCurrentAlarm2: false, //通道是否漏电电流报警
        leakageCurrentUpper2: 500, //漏电电流报警阈值
        leakageVoltageAlarm2: false, //通道是否漏电电压报警
        leakageVoltageUpper2: 100, //漏电电压报警阈值
      },
      rules: {
        // name: { required: true, type: 'string', max: 20, message: '名称不能为空且长度必须少于20个字符', trigger: 'blur' },
        // topCode: { required: true, type: 'string', max: 20, message: '编码不能为空且长度必须少于20个字符', trigger: 'blur' },
        // location: { required: false, type: 'string', max: 50, message: '安装位置长度必须少于50个字符', trigger: 'blur' },
        // monitorCode: { required: true, type: 'string', max: 20, message: '编码不能为空且长度必须少于20个字符', trigger: 'blur' },
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  computed: {
    ...mapState('common', ['newLightTypes', 'deviceTypes', 'productCodes', 'bigTypes', 'smallTypes']),
    ...mapGetters('auth', ['showLang']),
    ...mapState('group', ['groupTreeSelectedNode']),
    allGroups: function(){
      if(!this.groupTreeSelectedNode.children)return[];
      let grps = [];
      if(this.groupTreeSelectedNode.id > 0){
        grps.push({id: this.groupTreeSelectedNode.id, name: this.groupTreeSelectedNode.title});
      }
      for(let node of this.groupTreeSelectedNode.children){
        this.getChildGroups(grps, node);
      }
      return grps;
    },
  },
  mounted: function () {
  },
  methods: {
    getChildGroups: function(list, node){
      list.push({id: node.id, name: node.title});
      for(let n of node.children){
        this.getChildGroups(list, n);
      }
    },
    ok: async function () {
      switch (this.item.code) {
        case "updateGroup": {
          if(this.form.groupId <= 0){
            this.$Message.warning('请选择一个分区');
            return;
          }
          let args = {
            groupId: this.form.groupId,
          }
          this.$emit('saved', { code: this.item.code, args });
          break;
        }
        case 'updateUseType': {
          if(this.form.useType <= 0){
            this.$Message.warning('请选择一个应用类型');
            return;
          }
          let args = {
            type: this.form.useType, //单灯类型
          }
          this.$emit('saved', { code: this.item.code, args });
          break;
        }
        case 'updateLightGroup': {
          if(this.form.lightGroupId <= 0){
            this.$Message.warning('请选择一个灯控分组');
            return;
          }
          let args = {
            lightGroupId: this.form.lightGroupId, //单独所属控制区域
          }
          this.$emit('saved', { code: this.item.code, args });
          break;
        }
        case 'updateConnection': {
          if(this.form.deviceType <= 0){
            this.$Message.warning('请选择一个灯控设备类型');
            return;
          }
          let args = {
            deviceType: this.form.deviceType,
            gatewayId: this.form.gatewayId,
            channel: this.form.channel,
          }
          this.$emit('saved', { code: this.item.code, args });
          break;
        }
        case 'updateVoltageAlarm': {
          let args = {
            voltageAlarm: this.form.voltageAlarm, //额定电压
            voltageLower: this.form.voltageLower, //电压阈值上限
            voltageUpper: this.form.voltageUpper, //电压阈值下限
          }
          this.$emit('saved', { code: this.item.code, args });
          break;
        }
        case 'updateTempAlarm': {
          let args = {
            tempAlarm: this.form.tempAlarm, //温度阈值
            tempLevel1: this.form.tempLevel1, //温度阈值
            tempLevel2: this.form.tempLevel2, //温度阈值
          }
          this.$emit('saved', { code: this.item.code, args });
          break;
        }
        case 'updateChannel': {
          if(!this.form.enable1 && !this.form.enable2){
            this.$Message.warning('至少启用一个通道');
            return;
          }
          let args = {
            enable1: this.form.enable1, //控制使能
            before1: this.form.before1, //名称
            after1: this.form.after1, //额定功率
            count1: this.form.count1, //灯具类型
            adjust1: this.form.adjust1, //灯具功率
            enable2: this.form.enable2, //控制使能
            before2: this.form.before2, //名称
            after2: this.form.after2, //额定功率
            count2: this.form.count2, //灯具类型
            adjust2: this.form.adjust2, //灯具功率
          }
          this.$emit('saved', { code: this.item.code, args });
          break;
        }
        case 'updatePlanMode': {
          let args = {
            mode1: this.form.mode1, //时间表ID
            planId11: this.form.planId11, //时间表ID
            planId21: this.form.planId21, //时间表ID
            mode2: this.form.mode2, //时间表ID
            planId12: this.form.planId12, //时间表ID
            planId22: this.form.planId22, //时间表ID
          }
          this.$emit('saved', { code: this.item.code, args });
          break;
        }
        case 'updateCurrentAlarm': {
          let args = {
            currentAlarm1: this.form.currentAlarm1,
            currentLower1: this.form.currentLower1, //温度阈值
            currentUpper1: this.form.currentUpper1, //温度阈值
            currentAlarm2: this.form.currentAlarm2,
            currentLower2: this.form.currentLower2, //温度阈值
            currentUpper2: this.form.currentUpper2, //温度阈值
          }
          this.$emit('saved', { code: this.item.code, args });
          break;
        }
        case 'updateLeakageAlarm': {
          let args = {
            leakageCurrentAlarm1: this.form.leakageCurrentAlarm1,
            leakageCurrentUpper1: this.form.leakageCurrentUpper1,
            leakageVoltageAlarm1: this.form.leakageVoltageAlarm1,
            leakageVoltageUpper1: this.form.leakageVoltageUpper1,
            leakageCurrentAlarm2: this.form.leakageCurrentAlarm2,
            leakageCurrentUpper2: this.form.leakageCurrentUpper2,
            leakageVoltageAlarm2: this.form.leakageVoltageAlarm2,
            leakageVoltageUpper2: this.form.leakageVoltageUpper2,
          }
          this.$emit('saved', { code: this.item.code, args });
          break;
        }
        default: {
          this.$Message.warning(this.showLang('com.tips.no.batch.edit.type'));
          return;
        }
      }
      this.showModal = false;
    },
    cancel() {
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.form-item-group {
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header {
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
</style>